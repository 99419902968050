import React from "react"
import { Section, Container } from "../components/utils"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Section>
      <Container>
        <SEO title="404: Not found" />
        <h1>That page doesn't exist...</h1>
        <p>
          Sorry, looks like someone has cleared up this page... Try going back{" "}
          <a href="/">home</a>, or get in touch if you think there is a problem.
        </p>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
